import Entity from '../entity'

export default class Product extends Entity<number>{
    product_type_id: number;
    available_id: number;
    name: string;
    code: string;
    image: string;
    points: number;
    price: number;
    top_license: number;
    active: boolean;
    initial: boolean;
    active_user: boolean;
    generate_matrix: boolean;

    quantity: number;
    image_url: string;

}


