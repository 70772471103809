import {Store,Module,ActionContext, MutationTree} from 'vuex'
import ListState from './list-state'
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import Util from '../../lib/util'

export default class ListModule<T extends ListState<U>,R,U> implements Module<ListState<U>,R>{
    namespaced = true;
    state={  
        totalCount:0,
        currentPage:1,
        pageSize: 5,  
        list:new Array<U>(),
        loading: false,
        editModel: null
    };
    base_actions = {
        async getAll(context: ActionContext<T, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<U>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
            return context.state.list;
        },
        async create(context: ActionContext<T, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if ((context.state as any).activeList != undefined)
                (context.state as any).activeList = [];
            return response.data.result;
        },
        async update(context: ActionContext<T, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if ((context.state as any).activeList != undefined) {
                (context.state as any).activeList.forEach((item, k) => {
                    if (item.id == payload.data.id)
                        (context.state as any).activeList[k] = Util.extend((context.state as any).activeList[k], payload.data);
                });
            }
            return response.data.result;
                
        },
        async delete(context: ActionContext<T, any>, payload: any) {
            context.state.loading = true;
            let id = payload.data.id;
            await Ajax.delete('/api/' + (context.state as any).path + '/' + id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if ((context.state as any).activeList != undefined && (context.state as any).activeList != null && (context.state as any).activeList > 0) {
                (context.state as any).activeList = (context.state as any).activeList.filter((item: any) => {
                    return item.id !== id;
                });
            }
        },
        async get(context: ActionContext<T, any>, payload: any) {
            if (payload.id == undefined) return {};
            let existing = context.state.list.filter((item: any) => {
                return item.id == payload.id;
            });

            let existing2 = [];
            if ((context.state as any).activeList != undefined) {
                existing2 = (context.state as any).activeList.filter((item: any) => {
                    return item != null && item.id == payload.id;
                });
            }

            if (existing.length > 0) return existing[0];            
            if (existing2.length > 0) return existing2[0];            

            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            let item = reponse.data.result as U;
            if ((context.state as any).activeList != undefined) 
                (context.state as any).activeList.push(item);
            context.state.loading = false;
            return item;
        },
        async getAllActive(context: ActionContext<T, any>, payload: any) {
            let force = (payload.data != undefined && payload.data['force'] == true ? true : false);
            if ((context.state as any).activeList != undefined && (context.state as any).activeList.length > 0 || (context.state.loading && !force)) return true;
            let params = (payload.data == undefined ? {} : payload.data);
            delete params['force'];
            params.perPage = -1;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: params}).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            if ((context.state as any).activeList != undefined) 
                (context.state as any).activeList = reponse.data.result;
            context.state.loading = false;
        },
        async querySearch(context: ActionContext<T, any>, payload: any) {
            if (payload.data.query == null || payload.data.query == 'null') return [];
            if ((context.state as any).activeList != undefined && (context.state as any).activeList.length > 0) {
                let result = (context.state as any).activeList.filter((item: any) => {
                    return item != null && (item[payload.data.filter].toUpperCase().indexOf(payload.data.query.toUpperCase()) >= 0);
                });

                if (result.length > 0)
                    return result;
            }
            if (context.state.loading) return (context.state as any).activeList;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/query-search', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            (context.state as any).activeList = reponse.data.result;
            context.state.loading = false;
            return reponse.data.result;
        }
    };
    base_mutations = {
        setCurrentPage(state: T, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: T, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: T, model: U) {
            state.editModel = model;
        },
        view(state: T, model: U) {
            state.editModel = model;
        },
        new(state: T, model: U) {
            state.editModel = Object.assign({});
        },
        setList(state: T, list: any) {
            if (list == null) list = [];
            state.list = list;
            state.totalCount = state.list.length;
        },
        setActiveList(state: T, list: any) {
            if (list == null) list = [];
            (state as any).activeList = list;
        }
    }
}