import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Page from '../../entities/cms/page'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface PageState extends ListState<Page>{
    editModel: Page;
    versions: Array<any>;
}
class PageModule extends ListModule<PageState, any, Page>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Page>(),
        loading:false,
        editModel:new Page(),
        activeList: new Array<Page>(),
        versions: new Array<any>(),
        path: 'pages'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getVersions(context: ActionContext<PageState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/pages/' + payload.data.id + '/versions').catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.versions = response.data.result;
            return context.state.versions;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: PageState, model: Page) {
            state.editModel = model;
            state.versions = [];
        },
        new(state: PageState, model: Page) {
            state.editModel = Object.assign({});
            state.versions = [];
        },
    });
}
const pageModule=new PageModule();
export default pageModule;