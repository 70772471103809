import Entity from '../entity'

export default class CoinPayment extends Entity<number>{
    order_id: string;
    buyer_name: string;
    buyer_email: string;
    address: string;
    amount_total_fiat: number;
    amount: string;
    amountf: string;
    coin: string;
    time_expires: string;
    currency_code: string;
    confirms_needed: number;
    payment_address: string;
    qrcode_url: string;
    received: string;
    receivedf: string;
    recv_confirms: string;
    status: string;
    status_text: string;
    status_url: string;
    checkout_url: string;
    redirect_url: string;
    cancel_url: string;
    timeout: string;
    txn_id: string;
    type: string;
    payload: string;
}


