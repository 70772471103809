import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Company from '../../entities/admin/company'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CompanyState extends ListState<Company>{
    editModel:Company;
    
}
class CompanyModule extends ListModule<CompanyState,any,Company>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Company>(),
        loading:false,
        editModel: new Company(),
        activeList: new Array<Company>(),
        path: 'companies'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async update(context: ActionContext<CompanyState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
        },
        async resetDB(context: ActionContext<CompanyState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/reset-db', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const companyModule=new CompanyModule();
export default companyModule;