import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import DocumentType from '../../entities/network/documenttype'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface DocumentTypeState extends ListState<DocumentType>{
    editModel:DocumentType;
    
}
class DocumentTypeModule extends ListModule<DocumentTypeState,any,DocumentType>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<DocumentType>(),
        loading:false,
        editModel: new DocumentType(),
        activeList: new Array<DocumentType>(),
        path: 'document-types'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const documenttypeModule=new DocumentTypeModule();
export default documenttypeModule;