import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Payment from '../../entities/cashiers/payment'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface PaymentState extends ListState<Payment>{
    editModel:Payment;    
    opeModel:any;    
    activeList: Array<Payment>;
    reportList: Array<any>;
    loadingNumber: boolean;
}
class PaymentModule extends ListModule<PaymentState,any,Payment>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Payment>(),
        loading:false,
        loadingNumber: false,
        editModel: new Payment(),
        opeModel: null,
        activeList: new Array<Payment>(),
        reportList: new Array<any>(),
        path: 'payments'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PaymentState, any>, payload: any) {
            //if (payload.data.reference == undefined || payload.data.reference == '') return;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Payment>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
            
        },
        async create(context: ActionContext<PaymentState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });            
            let data = response.data.result;
            data['state'] = { 'name': 'Valido' };
            context.state.list.push(data);
            context.state.totalCount = context.state.list.length;
            context.state.loading = false;
        },
        async delete(context: ActionContext<PaymentState, any>, payload: any) {
            let idx = -1;
            if (payload.data.id > 0) {
                context.state.loading = true;
                let id = payload.data.id;
                let reponse = await Ajax.delete('/api/' + (context.state as any).path + '/' + id).catch(e => {
                    context.state.loading = false;
                    return Promise.reject(e);
                });
                context.state.loading = false;
                context.state.list = reponse.data.result;
                context.state.list.forEach((item, index) => {
                    if (item['state'].code == 'R')
                        item['class'] = ' removed_item';
                });

            } else {
                context.state.list.forEach((det, index) => {
                    if (det.amount == payload.data.amount && det.currency_id == payload.data.currency_id)
                        idx = index;
                });
                if (idx < 0) return;
                context.state.list.splice(idx, 1);
            }
        },
        async confirm(context: ActionContext<PaymentState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/payments/confirm', { id: payload.data.id }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async getLastNumber(context: ActionContext<PaymentState, any>, payload: any) {
            let result = null;
            context.state.loadingNumber = true;
            await Ajax.get('/api/payments/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.document_number = result;
            context.state.loadingNumber = false;
            return result;
        },
        async print(context: ActionContext<PaymentState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/payment-receipt', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');
            if (mode == 'LOCAL' && useRemote == 1) {
                let printer = response.data.result.printer;
                let url: any = localUrl + "?port_type=" + printer.port_type.code + "&characters=" + printer.characters + "&name=" + printer.name + "&lines=" + JSON.stringify(response.data.result.lines);
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                return '';
            } else
                return response.data.result;
        },
        async reportPaymentMoves(context: ActionContext<PaymentState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/payment-moves', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PaymentState, model: any) {
            state.opeModel = model;
            //let document = state.editModel.document_number;
            state.editModel = Object.assign({});
            state.editModel.payment_date = Util.abp.clock.today();
            state.editModel.payment_method_id = Util.abp.setting.get('DEFAULT_PAYMENT_METHOD');
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            if (state.editModel.document_number == null)
                state.editModel.document_number = '';
            state.editModel.amount = 0;
            if (model != undefined) {                
                state.editModel.amount = model.balance;
            } else 
                state.editModel.amount = 0;

            if (model != undefined && model.currency_id != undefined && model.currency_id != state.editModel.currency_id) 
                state.editModel.currency_id = model.currency_id;
        },
        add(state: PaymentState, model: any) {
            if (state.list == undefined) state.list = [];
            model.internal = true;
            state.list.push(model);
            state.totalCount = state.list.length;
        }
    });
}
const paymentModule=new PaymentModule();
export default paymentModule;
