import Entity from '../entity'

export default class PaymentMethod extends Entity<number>{
    name: string;
    type: string;
    active: boolean;
    discount: number;
    require_document: boolean;
}

