import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SaleDetail from '../../entities/sales/saledetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import Sale from '@/store/entities/sales/sale';

interface SaleDetailState extends ListState<SaleDetail>{
    editModel:SaleDetail;    
    sale: number;
}
class SaleDetailModule extends ListModule<SaleDetailState,any,SaleDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<SaleDetail>(),
        loading:false,
        editModel: new SaleDetail(),
        activeList: new Array<SaleDetail>(),
        sale: 0,
        path: 'sales'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<SaleDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: SaleDetailState, model: SaleDetail) {
            state.editModel = model;
        },
        init(state: SaleDetailState, model: Sale) {
            state.editModel = Object.assign({});
            if (model != undefined) {
                state.sale = model.id;
                state.list = model.details;
            }
        },
        add(state: SaleDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
                exist[0].total = exist[0].quantity * exist[0].price;
            } else {
                let detail = new SaleDetail();
                detail.sale_id = state.editModel.id;
                detail.product = product;
                detail.product_id = product.id;
                detail.product_name = product.name;
                detail.quantity = product.quantity;
                detail.price = parseFloat(String(product.price));
                detail.total = parseFloat((detail.quantity * detail.price).toFixed(6));
                Util.updateTotalDetails(detail);
                detail.points = product.points;
                detail.product_price = product['product_price'];
                state.list.push(detail);
            }
        },
        updateList(state: SaleDetailState, data: any) {
            state.list[data.index].quantity = data.detail.quantity;
            state.list[data.index].price = data.detail.price;
            state.list[data.index].total = data.detail.total;
            state.list[data.index].points = data.detail.points;
        },
        delete(state: SaleDetailState, index: number) {
            state.list.splice(index, 1);
        },
    });
}
const saledetailModule=new SaleDetailModule();
export default saledetailModule;