import Entity from '../entity'

export default class Subscription extends Entity<number>{
    customer_id: number;
    top_license: number;
    earned: number;
    balance: number;
    date_start: string;
    date_finish: string;
    active: boolean;
}


