import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Sale from '../../entities/sales/sale'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import appconst from '../../../lib/appconst'
import PageResult from '@/store/entities/page-result';
import SaleDetail from '@/store/entities/sales/saledetail';
import SaleHistory from '@/store/entities/sales/history';
import Address from '@/store/entities/network/address';
import Currency from '@/store/entities/preferences/currency';

interface SaleState extends ListState<Sale>{
    editModel:Sale;       
    hisLoading: boolean;
    changed: boolean;        
    history: Array<SaleHistory>;   
    reportList: Array<any>;
    filename: string;
}
class SaleModule extends ListModule<SaleState,any,Sale>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Sale>(),
        loading:false,
        hisLoading: false,
        changed: false,
        editModel: new Sale(),
        activeList: new Array<Sale>(),
        history: new Array<SaleHistory>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'sales'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<SaleState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Sale>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }

            return context.state.list;
        },
        async update(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async cancel(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/sales/cancel', { id: payload.data.id, cancel_reason: payload.data.cancel_reason } ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async approve(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/sales/approve', { id: payload.data.id }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async complete(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/sales/complete', { id: payload.data.id }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async getHistory(context: ActionContext<SaleState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/sales/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },

        async reportSalesQuery(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-query', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesProducts(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },     
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: SaleState, model: Sale) { 
            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY');
            currency.sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            currency.conversion_rate = 1;
            state.editModel = Object.assign({});

            state.editModel.customer = Object.assign({});
            state.editModel.customer_id = Util.abp.setting.get('DEFAULT_SPONSOR');
            state.editModel.customer_name = Util.abp.setting.get('DEFAULT_CUSTOMER_NAME');

            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.customer_id = Util.abp.setting.get('DEFAULT_SPONSOR');
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency = currency;
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.exchange_rate = 1;
            state.editModel.total = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.number = '0';
            state.editModel.serie = '';
            state.editModel.details = new Array<SaleDetail>();
            state.changed = false;
        },
        edit(state: SaleState, model: Sale) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.customer = model.customer;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_state = Util.abp.list.getItem(model.customer.state_id);
            state.editModel.customer_email = model.customer.email;
            state.changed = false;
        },
        setReportList(state: SaleState, list: Array<any>) {
            state.reportList = list;
            state.totalCount = list.length;
        },
        setCurrency(state: SaleState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
        },
        setCustomer(state: SaleState, customer: any) {
            state.editModel.customer = customer;
            state.editModel.customer_id = customer.id;
        },
        updatePayments(state: SaleState, total_paid: any) {
            state.editModel.total_paid = parseFloat(total_paid);
            state.editModel.balance = parseFloat((state.editModel.total - state.editModel.total_paid).toFixed(2));
        },
        setTotals(state: SaleState, data: any) {
            state.editModel.total = parseFloat((data.total).toFixed(6));
            state.editModel.total_paid = parseFloat((data.total_paid).toFixed(6));
            state.editModel.balance = parseFloat((data.balance).toFixed(6));
            state.editModel.points = parseFloat((data.points));
        },
    });
}
const saleModule=new SaleModule();
export default saleModule;