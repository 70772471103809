import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Subscription from '../../entities/network/subscription'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import axios from 'axios'
import Address from '../../entities/network/address'

interface SubscriptionState extends ListState<Subscription>{
    editModel: Subscription;
}
class SubscriptionModule extends ListModule<SubscriptionState,any,Subscription>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Subscription>(),
        loading:false,
        editModel: new Subscription(),
        activeList: new Array<Subscription>(),
        path: 'subscriptions'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getActive(context: ActionContext<SubscriptionState, any>, payload: any) {
            context.state.loading = true;
            context.state.editModel = new Subscription();
            let response = await Ajax.get('/api/subscriptions/active/' + payload.data.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.editModel = response.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const subscriptionModule=new SubscriptionModule();
export default subscriptionModule;