import Entity from '../entity'

export default class Menu extends Entity<number>{
    parent_id: number;
    code: string;
    name: string;
    level_depth: number;
    title: string;
    icon: string;
    active: boolean;
    show: boolean;
    position: number;
}


