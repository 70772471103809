import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import BonusDetail from '../../entities/catalogs/bonusdetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface BonusDetailState extends ListState<BonusDetail> {
    editModel: BonusDetail;
    activeList: Array<BonusDetail>;
    typeCode: string;
    bonus: number;
}
class BonusDetailModule extends ListModule<BonusDetailState, any, BonusDetail>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<BonusDetail>(),
        loading: false,
        typeCode: '',
        bonus: 0,
        editModel: new BonusDetail(),
        activeList: new Array<BonusDetail>(),
        path: 'bonus-details',
    }
    actions = {
        async getAll(context: ActionContext<BonusDetailState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            payload.data.bonus = context.state.bonus;
            let reponse = await Ajax.get('/api/bonus-details', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
            return context.state.list;
        },
        async create(context: ActionContext<BonusDetailState, any>, payload: any) {
            context.state.loading = true;
            payload.data.bonus_id = context.state.bonus;
            var result = await Ajax.post('/api/bonus-details', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list.push(result.data.result);
            context.state.loading = false;
        },
        async update(context: ActionContext<BonusDetailState, any>, payload: any) {
            context.state.loading = true;
            payload.data.bonus_id = context.state.bonus;
            var result = await Ajax.put('/api/bonus-details/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            //context.state.list = result.data.result;
            context.state.loading = false;
        },
        async delete(context: ActionContext<BonusDetailState, any>, payload: any) {
            context.state.loading = true;
            let id = payload.data.id;
            await Ajax.delete('/api/bonus-details/' + id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list = context.state.list.filter((item: any) => {
                return item.id !== id;
            });
        },
    };
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setActiveList(state: BonusDetailState, values: Array<BonusDetail>) {
            if (values != null) {
                state.activeList = values;
            } else
                state.activeList = new Array<BonusDetail>();
        },
        setList(state: BonusDetailState, values: Array<BonusDetail>) {
            state.list = [];
            state.totalCount = 0;
            if (values != null) {
                state.loading = true;
                state.list = values;
                state.totalCount = values.length;
                state.loading = false;
            }
        },
        setType(state: BonusDetailState, type: string) {
            state.typeCode = type;
        },
        setBonus(state: BonusDetailState, id: number) {
            state.bonus = id;
        }
    });
}
const bonusdetailModule = new BonusDetailModule();
export default bonusdetailModule;