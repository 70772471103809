import Entity from '../entity'
import BonusDetail from './bonusdetail';

export default class Bonus extends Entity<number>{
    name: string;
    code: string;
    type_id: number;
    matrix_id: number;
    accumulate: boolean;
    top_range: boolean;
    frecuency_day: number;
    frecuency_id: number;
    active: boolean;
    require_approve: boolean;
    products: Array<any>;

    details: Array<BonusDetail>;
}


