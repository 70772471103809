import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import WalletTransaction from '../../entities/cashiers/wallettransaction'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Customer from '@/store/entities/network/customer';

interface WalletTransactionState extends ListState<WalletTransaction>{
    editModel: WalletTransaction;
    customer: Customer;
    reportList: Array<any>;
}
class WalletTransactionModule extends ListModule<WalletTransactionState,any,WalletTransaction>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<WalletTransaction>(),
        loading: false,
        editModel: new WalletTransaction(),
        customer: new Customer(),
        activeList: new Array<WalletTransaction>(),
        reportList: new Array<any>(),
        path: 'wallet-transactions'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<WalletTransactionState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/wallet-transactions', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result;
            context.state.totalCount = page.total;
            context.state.list = page.data;

            if (context.state.list != undefined) {
                context.state.list.forEach(item => {
                    let state = Util.abp.list.getItem(item.state_id);
                    if (state.code == 'AN')
                        item['class'] = ' removed_item';
                    else {
                        item['icon'] = (item['sign'] == 1 ? 'arrow_upward' : 'arrow_downward');
                        item['color'] = (item['sign'] == 1 ? '#4CAF50' : '#F44336');
                    }
                });
            }
        },
        async confirm(context: ActionContext<WalletTransactionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/wallet-transactions/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async confirmAll(context: ActionContext<WalletTransactionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/wallet-transactions/confirm-all', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async verify(context: ActionContext<WalletTransactionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/wallet-transactions/verify', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async payment(context: ActionContext<WalletTransactionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/wallet-transactions/payment', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async delete(context: ActionContext<WalletTransactionState, any>, payload: any) {
            context.state.loading = true;
            let id = payload.data.id;
            await Ajax.delete('/api/wallet-transactions/' + id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },     
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations 
        new(state: WalletTransactionState, model: WalletTransaction) {
            let date = new Date();

            state.editModel = Object.assign({});
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            if (state.customer != null)
                state.editModel.customer_id = state.customer.id;
            state.editModel.process_date = Util.abp.clock.today();
            state.editModel.process_date = Util.abp.clock.today();
            state.editModel.sign = 1;
            state.editModel.type_id = Util.abp.setting.get('DEFAULT_WALLET_TYPE_IN');            
        },
        edit(state: WalletTransactionState, model: WalletTransaction) {
            state.editModel = model;            
        },
        setCustomer(state: WalletTransactionState, model: Customer) {
            state.customer = model;
            if (model != null && model['range'] != null)
                state.customer['range_name'] = model['range'].name;
            
        }
    });
}
const wallettransactionModule=new WalletTransactionModule();
export default wallettransactionModule;