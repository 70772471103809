declare global {
    interface RouterMeta {
        title: string;
    }
    interface Router { 
        path: string;
        name: string;
        icon?: string;
        permission?: string;
        meta?: RouterMeta;
        component: any; 
        children?: Array<Router>;
    } 
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import login from '../views/login.vue'
import home from '../views/home/home.vue'
import main from '../views/main.vue'
import Ajax from '../lib/ajax';

export const locking = { 
    path: '/locking',
    name: 'locking',
    component: () => import('../components/lockscreen/components/locking-page.vue')
};
export const loginRouter: Router = {
    path: '/',
    name: 'login',
    meta: {
        title: 'LogIn'
    },
    component: () => import('../views/login.vue')
};
export const otherRouters: Router = {
    path: '/main',
    name: 'main',
    permission: '',
    meta: { title: '' },
    component: main,
    children: [
        { path: 'home', meta: { title: 'Home' }, name: 'home', component: () => import('../views/home/home.vue') },
        { path: '/admin/users/profile', meta: { title: 'Perfil de usuario' }, name: 'profile', component: () => import("../views/admin/user/profile.vue") },
        { path: '/reports', meta: { title: 'Reportes' }, name: 'reports', component: () => import("../views/reports/reports.vue") },
        { path: '/reports/sales-query', meta: { title: 'Consulta de Ventas' }, name: 'rsalesquery', component: () => import("../views/reports/sales/sales-query.vue") },
        { path: '/reports/sales-products', meta: { title: 'Ventas por Producto' }, name: 'rsalesproducts', component: () => import("../views/reports/sales/sales-products.vue") },

        { path: '/reports/networkquery', meta: { title: 'Informe de Red' }, name: 'rnetworkquery', component: () => import("../views/reports/network/network-query.vue") },
        { path: '/reports/pagos-movimientos', meta: { title: 'Pagos y Movimiento de Efectivo' }, name: 'rpaymentmoves', component: () => import("../views/reports/cashiers/payment-moves.vue") },

    ]
}
export const appRouters: Array<Router> = [];

export const dbRouters = {
    user: () => import("../views/admin/user/user.vue"),
    role: () => import("../views/admin/role/role.vue"),
    //auditLog: () => import("../views/admin/auditLog/auditLog.vue"),
    tab: () => import("../views/admin/tab/tab.vue"),
    icon: () => import("../views/admin/icon/icon.vue"),
    company: () => import("../views/admin/company/company.vue"),
    
    customer: () => import("../views/network/customer/customer.vue"),
    node: () => import("../views/network/network/network.vue"),
    documenttype: () => import("../views/network/documenttype/documenttype.vue"),
    gender: () => import("../views/network/gender/gender.vue"),

    bonus: () => import("../views/catalogs/bonus/bonus.vue"),
    range: () => import("../views/catalogs/range/range.vue"),
    product: () => import("../views/catalogs/product/product.vue"),

    page: () => import("../views/cms/page/page.vue"),
    
    paymentmethod: () => import("../views/cashiers/paymentmethod/paymentmethod.vue"),
    payment: () => import("../views/cashiers/payment/payment.vue"),
    wallet: () => import("../views/cashiers/wallet/wallet.vue"),
    coinpayment: () => import("../views/cashiers/coinpayment/coinpayment.vue"),
     
    sale: () => import("../views/sales/sale/sale.vue"),
        
    type: () => import("../views/preferences/generaltype/generaltype.vue"),
    country: () => import("../views/preferences/country/country.vue"),
    currency: () => import("../views/preferences/currency/currency.vue"),
    settings: () => import("../views/preferences/configuration/configuration.vue"),
    //language: () => import("../views/preferences/language/language.vue")
} 

export const routers = [
    loginRouter,
    locking,
    ...appRouters,
    otherRouters
];
