import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Bonus from '../../entities/catalogs/bonus'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface BonusState extends ListState<Bonus>{
    editModel: Bonus;
}
class BonusModule extends ListModule<BonusState, any, Bonus>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Bonus>(),
        loading:false,
        editModel:new Bonus(),
        activeList: new Array<Bonus>(),
        path: 'bonuses'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: BonusState, model: Bonus) {
            state.editModel = Object.assign({});
            state.editModel.type_id = Util.abp.list.get('T_BONUS', 'LE').id;
            state.editModel.matrix_id = Util.abp.setting.get('DEFAULT_MATRIX');
            state.editModel.active = true;
        },
    });
}
const bonusModule=new BonusModule();
export default bonusModule;