import { Store, Module, ActionContext, MutationTree } from 'vuex'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface DashboardState {
    activity: any;
    sales: any;
    widgets: any;
    widget_sales: Array<any>;
    widget_wallet: any;
    widget_coinpayment: any;
    bonuses: any;
    ranges: any;
    categories: any;
    loading: boolean;
    
}
class DashboardModule implements Module<DashboardState, any>{
    namespaced = true;
    state={
        activity: {},
        sales: [],
        widgets: {},
        widget_sales: [],
        widget_wallet: {},
        widget_coinpayment: {},
        bonuses: [],
        ranges: [],
        categories: [],
        loading:false,
    }
    actions = {
        async getDashActivity(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/dash-activities', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return false;
            });
            if (reponse) {
                context.state.loading = false;
                context.state.activity = (reponse as any).data.result;
                return true;
            } else 
                return false;
        },
        async getDashSales(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/dash-sales', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return false;
            });
            if (reponse) {
                context.state.loading = false;
                context.state.sales = (reponse as any).data.result;
                return true;
            } else
                return false;
        },
        async getDashBonus(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/dash-bonus', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.bonuses = response.data.result.series;
            context.state.categories = response.data.result.categories;
        },
        async getDashRanges(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/dash-ranges', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.ranges = response.data.result;
        },
        async getWidgetValues(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/dash-widgets', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.widgets = response.data.result;
            return context.state.widgets;
        },
        async getWidgetSales(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/sales-widgets', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            if (reponse) {
                context.state.widget_sales = (reponse as any).data.result;
                return context.state.widget_sales;
            } else
                return false;
        },
        async getWidgetWallet(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/customer-widgets', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            if (reponse) {
                context.state.widget_wallet = (reponse as any).data.result;
                return context.state.widget_wallet;
            } else
                return false;
        },
        async getWidgetCoinPayment(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/coinpayment-widgets', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            if (reponse) {
                context.state.widget_coinpayment = (reponse as any).data.result;
                return context.state.widget_coinpayment;
            } else
                return false;
        },
    };
    mutations = {
        setWidgetWallet(state: DashboardState, list: any) {
            state.widget_wallet = list;
        },
    };
}
const dashboardModule = new DashboardModule();
export default dashboardModule;