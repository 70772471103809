import Entity from '../entity'

export default class BonusDetail extends Entity<number>{
    bonus_id: number;
    product_id: number;
    range_id: number;
    level: number;
    nodes: number;
    max_level: number;
    activation: number;
    first_amount: number;
    amount: number;
}


