import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Wallet from '../../entities/cashiers/wallet'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';
import WalletHistory from '@/store/entities/sales/history';
import Customer from '@/store/entities/network/customer';

interface WalletState extends ListState<Wallet>{
    editModel: Wallet;
    customer: Customer;
    reportList: Array<any>;
}
class WalletModule extends ListModule<WalletState,any,Wallet>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Wallet>(),
        loading: false,
        editModel: new Wallet(),
        customer: new Customer(),
        activeList: new Array<Wallet>(),
        reportList: new Array<any>(),
        path: 'wallets'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async clousure(context: ActionContext<WalletState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/wallets/clousure', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations 
        new(state: WalletState, model: Wallet) {
            let date = new Date();

            state.editModel = Object.assign({});
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            
        },
        edit(state: WalletState, model: Wallet) {
            state.editModel = model;            
        },
        setCustomer(state: WalletState, model: Customer) {
            state.customer = model;
        }
    });
}
const walletModule=new WalletModule();
export default walletModule;