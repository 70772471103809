import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Address from '../../entities/network/address'
import Util from '../../../lib/util'

interface AddressState extends ListState<Address>{
    editModel:Address;
}
class AddressModule extends ListModule<AddressState,any,Address>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Address>(),
        loading:false,
        editModel: new Address(),
        activeList: new Array<Address>(),
        path: 'addresses'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const addressModule=new AddressModule();
export default addressModule;