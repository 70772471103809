import Entity from '../entity'

export default class Address extends Entity<number>{
    country_id: number;
    city_id: number;
    address1: string;
    address2: string;
    postcode: string;
    other: string;

    city: any;
}


