import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import { Store, Module, ActionContext } from 'vuex'

interface TwoFactorState {
    auth: any,
    codes: Array<any>,
    loading: boolean,
}
class TwoFactorStore implements Module<TwoFactorState, any>{
    namespaced = true;
    state = {
        auth: null,
        codes: [],
        loading: false,
    }
    actions = {
        async verify(context: ActionContext<TwoFactorState, any>, payload: any) {
            context.state.loading = true;
            let rep = await Ajax.get('/api/2fa/verify', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return rep.data.result;
        },
        async enable(context: ActionContext<TwoFactorState, any>, payload: any) {
            context.state.loading = true;
            let rep = await Ajax.get('/api/2fa/enable', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.auth = rep.data.result;
            return rep.data.result;
        },
        async confirm(context: ActionContext<TwoFactorState, any>, payload: any) {
            context.state.loading = true;
            let rep = await Ajax.get('/api/2fa/confirm', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return rep.data.result;
        },
        async delete(context: ActionContext<TwoFactorState, any>, payload: any) {
            context.state.loading = true;
            let rep = await Ajax.delete('/api/2fa/', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.auth = null;
            return rep.data.result;
        },
        async generate(context: ActionContext<TwoFactorState, any>, payload: any) {
            context.state.loading = true;
            let rep = await Ajax.put('/api/2fa/recovery/generate').catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.codes = rep.data.result;
            return rep.data.result;
        },
        async get(context: ActionContext<TwoFactorState, any>) {
            context.state.loading = true;
            let rep = await Ajax.get('/api/2fa/recovery').catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.codes = rep.data.result;
            return rep.data.result;
        }
    }
    mutations = {
    }
}
const twofactor = new TwoFactorStore();
export default twofactor;