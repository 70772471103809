import Entity from '../entity'
export default class User extends Entity<number>{
    first_name:string;
    last_name:string;
    username:string;
    email:string;
    avatar_type:string;
    avatar_location:string;
    password:string;
    active: boolean;
    bo_menu: number;
    default_tab_id: number;
    roles: Array<any>;

    avatar_url: string;
}