import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Product from '../../entities/catalogs/product'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'

interface ProductState extends ListState<Product>{
    editModel:Product;
    activeList: Array<Product>;
}
class ProductModule extends ListModule<ProductState,any,Product>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Product>(),
        loading:false,
        editModel:new Product(),
        activeList: new Array<Product>(),
        path: 'products',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let item = response.data.result;
            if (response.data.result && context.state.activeList != undefined) {
                item.product_name = item.name;
                item.name = item.code + ' - ' + item.name
                context.state.activeList.push(item);
            }
            return item;
        },
        async getLastCode(context: ActionContext<ProductState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/products/last-code', { params: payload.data}).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ProductState, model: Product) {
            state.editModel = Object.assign({});
            if (model != null && model != undefined)
                state.editModel = model;
                        
            state.editModel.product_type_id = Util.abp.setting.get('DEFAULT_PRODUCT_TYPE');
            state.editModel.available_id = Util.abp.list.get('T_AVAIL','T').id;
            state.editModel.price = 0;
            state.editModel.top_license = 0;            
            state.editModel.code = '';
            state.editModel.image = null;            
        },
    });
}
const productModule=new ProductModule();
export default productModule;