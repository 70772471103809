import Entity from '../entity'

export default class Payment extends Entity<number>{
    customer_id: number;
    sale_id: number;
    currency_id: number;
    payment_method_id: number;
    capture: string;
    reference: string;
    amount: number;
    conversion_rate: number;
    document_number: string;
    card_number: string;
    card_brand: string;
    card_expiration: string;
    card_holder: string;
    state_id: number;
    payment_date: string;
    valid: boolean;

    currency_sign: string;
}


