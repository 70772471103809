import Entity from '../entity'
import SaleDetail from './saledetail';
import Customer from '../network/customer';
import Currency from '../preferences/currency';
import Payment from '../cashiers/payment';

export default class Sale extends Entity<number>{
    reference: string;
    customer_id: number;
    currency_id: number;
    currency_sign: string;
    state_id: number;
    exchange_rate: number;
    total: number;
    total_paid: number;
    balance: number;
    points: number;
    sale_date: string;
    serie: string;
    number: string;
    notes: string;
    valid: boolean;
    details: Array<SaleDetail>;
    payments: Array<Payment>;

    customer: Customer;
    currency: Currency;
    customer_state: any;
    customer_name: string;
    customer_email: string;
}


