import Entity from '../entity'
import Product from '../catalogs/product';

export default class SaleDetail extends Entity<number>{
    sale_id: number;
    product_id: number;
    product_name: string;
    quantity: number;
    price: number;
    total: number;
    product_price: number;
    points: number;
    
    product: Product;
    
}


