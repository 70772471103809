import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import app from './modules/app'
import session from './modules/session'
import user from './modules/admin/user'
import role from './modules/admin/role'
import tab from './modules/admin/tab'
import twofactor from './modules/admin/twofactor'
import company from './modules/admin/company'
import dashboard from './modules/admin/dashboard'
  
import customer from './modules/network/customer'
import subscription from './modules/network/subscription'
import address from './modules/network/address'
import documenttype from './modules/network/documenttype'
import node from './modules/network/node'
import gender from './modules/network/gender'
 
import bonus from './modules/catalogs/bonus'
import bonusdetail from './modules/catalogs/bonusdetail'
import bonuscalculation from './modules/catalogs/bonuscalculation'
import range from './modules/catalogs/range'
import product from './modules/catalogs/product'

import country from './modules/preferences/country'
import city from './modules/preferences/city'
import currency from './modules/preferences/currency'
import exchangerate from './modules/preferences/exchangerate'
import generaltype from './modules/preferences/generaltype'
import generaltypevalue from './modules/preferences/generaltypevalue'
import configuration from './modules/preferences/configuration'
import notification from './modules/preferences/notification'
import conversation from './modules/preferences/conversation'

import paymentmethod from './modules/cashiers/paymentmethod'
import payment from './modules/cashiers/payment'
import wallet from './modules/cashiers/wallet'
import wallettransaction from './modules/cashiers/wallettransaction'
import coinpayment from './modules/cashiers/coinpayment'

import page from './modules/cms/page'

import sale from './modules/sales/sale'
import saledetail from './modules/sales/saledetail'

const store = new Vuex.Store({
    state: {
        //
        //loading: boolean
    },
    mutations: {
        //
    },
    actions: {

    },
    modules: {
        app,
        session,
        user,
        role,
        tab,
        twofactor,
        company,
        dashboard,

        customer,
        subscription,
        address,
        documenttype,
        node,
        gender,

        bonus,
        bonusdetail,
        bonuscalculation,
        range,
        product,

        country,
        city,
        currency,
        exchangerate,
        configuration,
        generaltype,
        generaltypevalue,
        notification,
        conversation,

        paymentmethod,
        payment,
        wallet,
        wallettransaction,
        coinpayment,

        page,

        sale,
        saledetail,
    }
});

export default store;