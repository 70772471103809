import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Customer from '../../entities/network/customer'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import axios from 'axios'
import Address from '../../entities/network/address'

interface CustomerState extends ListState<Customer>{
    editModel: Customer;
    reportList: Array<any>;
    filename: string;
}
class CustomerModule extends ListModule<CustomerState,any,Customer>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Customer>(),
        loading:false,
        editModel: new Customer(),
        activeList: new Array<Customer>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'customers'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async update(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;

            await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async get(context: ActionContext<CustomerState, any>, payload: any) {
            if (payload.id == undefined) return {};
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            let item = reponse.data.result as Customer;
            context.state.loading = false;
            return item;
        },
        async activate(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.put('/api/customers/activate/' + payload.data.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async processRanges(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/customers/process-ranges').catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async reportNetworkQuery(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/network-query', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: CustomerState, model: Customer) {
            state.editModel = model;
            if (state.editModel.address == null) {
                state.editModel.address = new Address();
                state.editModel.address.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
                state.editModel.address.address1 = '';
                state.editModel.address.city_id = null;
            }
        },
        new(state: CustomerState, model: Customer) {
            state.editModel = Object.assign({});
            state.editModel.document_type_id = Util.abp.setting.get('DEFAULT_DOCUMENT');
            state.editModel.gender_id = 1;
            state.editModel.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
            state.editModel.sponsor_id = Util.abp.setting.get('DEFAULT_SPONSOR');
            state.editModel.sponsor = Util.abp.setting.get('DEFAULT_SPONSOR_ENTITY');
            state.editModel.exclude_range = false;
            state.editModel.founder = false;
            state.editModel.founder_amount = 0;            
            
            let addr = new Address();
            addr.id = 0;
            addr.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
            addr.address1 = '';
            addr.city_id = null;
            addr.other = 'Nueva';
        },
        setSponsor(state: CustomerState, model: Customer) {
            state.editModel.sponsor_id = model.id;
            state.editModel.sponsor = model;
        },
    });
}
const customerModule=new CustomerModule();
export default customerModule;