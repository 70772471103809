import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import CoinPayment from '../../entities/cashiers/coinpayment'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Customer from '@/store/entities/network/customer';

interface CoinPaymentState extends ListState<CoinPayment>{
    editModel: CoinPayment;
    reportList: Array<any>;
}
class CoinPaymentModule extends ListModule<CoinPaymentState,any,CoinPayment>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<CoinPayment>(),
        loading: false,
        editModel: new CoinPayment(),
        activeList: new Array<CoinPayment>(),
        reportList: new Array<any>(),
        path: 'coin-payments'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<CoinPaymentState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/coin-payments', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result;
            context.state.totalCount = page.total;
            context.state.list = page.data;

            if (context.state.list != undefined) {
                context.state.list.forEach(item => {
                    let state = Util.abp.list.getByValue('E_COINPAYMENT', item['status']);
                    item['type_text'] = (item['type'] == 'I' ? 'Ingreso' : 'Egreso');
                    if (state.code != '-1') {
                        item['icon'] = (item['type'] == 'I' ? 'arrow_upward' : 'arrow_downward');
                        item['color'] = (item['type'] == 'I' ? '#4CAF50' : '#F44336');
                    }
                    item['state_name'] = state.name;
                    item['state_color'] = state.extra;
                });
            }
        },
        async getHistory(context: ActionContext<CoinPaymentState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.get('/api/coin-payments/history', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async verify(context: ActionContext<CoinPaymentState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/coin-payments/verify', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async delete(context: ActionContext<CoinPaymentState, any>, payload: any) {
            context.state.loading = true;
            let id = payload.data.id;
            await Ajax.delete('/api/coin-payments/' + id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },     
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations 
    });
}
const coinpaymentModule=new CoinPaymentModule();
export default coinpaymentModule;