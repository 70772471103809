import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import User from '../../entities/admin/user'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface UserState extends ListState<User> {
    editModel: User;
    activeList: Array<User>;
}
class UserModule extends ListModule<UserState, any, User>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<User>(),
        loading: false,
        editModel: new User(),
        activeList: new Array<User>(),
        path: 'users'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            if (payload.data.roles == undefined && payload.data.rol_id != null)
                payload.data.roles = [payload.data.rol_id];

            payload.data['confirmed'] = '1';

            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (response.data.result)
                (context.state as any).activeList.push(response.data.result);
            return response.data.result;
        },
        async update(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            if (payload.data.roles == undefined && payload.data.rol_id != null)
                payload.data.roles = [payload.data.rol_id];

            await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            (context.state as any).activeList = [];
        },
        async updateBranch(context: ActionContext<UserState, any>, payload: any) {
            await Ajax.put('/api/users/branch', payload.data).catch(e => {
                return Promise.reject(e);
            });
        },
        async updateProfile(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            if (payload.data.roles == undefined && payload.data.rol_id != null)
                payload.data.roles = [payload.data.rol_id];

            let response = await Ajax.put('/api/user/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            (context.state as any).activeList = [];
        },
        async getAllActive(context: ActionContext<UserState, any>, payload: any) {
            let force = (payload.data != undefined && payload.data['force'] == true ? true : false);
            if (context.state.activeList != undefined && context.state.activeList.length > 0 || (context.state.loading && !force)) return true;
            let params = (payload.data == undefined ? {} : payload.data);
            delete params['force'];
            params.perPage = -1;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            if (context.state.activeList != undefined)
                context.state.activeList = reponse.data.result;
            context.state.loading = false;
   
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: UserState, model: User) {
            state.editModel = Object.assign({});
            state.editModel.active = true;
            state.editModel.bo_menu = 1;
        },
        edit(state: UserState, model: User) {
            state.editModel = model;
        },
    });
}
const userModule = new UserModule();
export default userModule;

